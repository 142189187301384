var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { addReverseIndexing, FileType, indexSelector } from "..";
import { createModelAndStructure } from "./showFile";
import { createTrajObjFromMultipleMols, showStructureWithTrajectoryInViewer, } from "./showStructureWithTraj";
import { createArrayFromStartToN } from "../utils/general_utils";
export function createMolstarModelsMultipleMols(molstar, mols, fileName, ftype, visibleElementIDs) {
    return __awaiter(this, void 0, void 0, function* () {
        let visibleEntryIDs;
        if (mols.length > 1 && visibleElementIDs && visibleElementIDs !== "all") {
            const entryIndexesAvail = createArrayFromStartToN(0, mols.length - 1);
            try {
                visibleEntryIDs = indexSelector(visibleElementIDs, addReverseIndexing(entryIndexesAvail));
            }
            catch (_a) { }
        }
        return yield createTrajObjFromMultipleMols(molstar, mols, fileName, ftype, visibleEntryIDs);
    });
}
export function createMolstarModels(molstar, mol, fileName, ftype
/*
returns either cellrefs or some system properties
*/
) {
    return __awaiter(this, void 0, void 0, function* () {
        const multipleFrameTypes = [
            FileType.trajectory,
            FileType.coordinates_group,
            FileType.ensemble,
        ];
        const multipleFrames = multipleFrameTypes.includes(ftype);
        if (multipleFrames) {
            return yield showStructureWithTrajectoryInViewer(molstar, mol, fileName, ftype);
        }
        const structure = yield createModelAndStructure(molstar, mol, fileName);
        const cellRefs = Array.isArray(structure)
            ? structure.map((s) => s.ref)
            : [structure.ref];
        return cellRefs;
    });
}
