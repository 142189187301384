var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FileType } from "../../";
import { useEffect, useState } from "react";
import { EntryDisplayControls } from "./EntryDisplayControls";
import { updateVisibleSystemEntriesState } from "./updateVisibleSystemEntriesState";
import { createArrayFromStartToN } from "../../utils/general_utils";
import { showSDFEntriesAsFrameEnsemble } from "./displayMultipleEntries";
import { getSDFTrajectory } from "./utils";
export function MultipleEntryOptions({ system, vss, NAPGenericStore, molstar, visibility = true, positionInTree, cellRefs, fileType, pyodide, }) {
    const cellRef = system === null || system === void 0 ? void 0 : system.cellRef;
    const sdftrajectory = cellRef
        ? getSDFTrajectory(molstar, cellRefs)
        : undefined;
    const numMols = (system === null || system === void 0 ? void 0 : system.numEntries) || 1;
    const trajCellRef = sdftrajectory === null || sdftrajectory === void 0 ? void 0 : sdftrajectory.transform.ref;
    const [wait, setWait] = useState(false);
    const predefinedIndexSelection = system === null || system === void 0 ? void 0 : system.visibleElementIDs;
    const [selection, setSelection] = useState(predefinedIndexSelection);
    function handleUpdateShownEntries(activeTextFieldVal) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!system)
                return;
            if (!activeTextFieldVal)
                return;
            const positionInTree = system.stateTreePosition;
            if (!positionInTree)
                return;
            setWait(true);
            yield showSDFEntriesAsFrameEnsemble(system, trajCellRef, molstar, pyodide, vss, activeTextFieldVal, numMols, positionInTree);
            setWait(false);
        });
    }
    function onVisibilityChange(activeTextFieldVal) {
        if (!numMols)
            return;
        //     // udate variable showing which entries are shown to be used for table interactivity
        updateVisibleSystemEntriesState(numMols, visibility, system, activeTextFieldVal, NAPGenericStore);
    }
    useEffect(() => {
        onVisibilityChange(selection);
        handleUpdateShownEntries(selection);
    }, [selection]);
    function onActiveElementsUpdate(activeTextFieldVal) {
        setSelection(activeTextFieldVal);
    }
    const options = createArrayFromStartToN(1, numMols);
    return (_jsx(EntryDisplayControls, { structCellRefs: options, predefinedIndexSelection: predefinedIndexSelection, fileType: FileType.sdf, visibility: visibility, onActiveElementsUpdate: onActiveElementsUpdate, onVisibilityChange: onVisibilityChange, disabled: wait || !sdftrajectory }));
}
