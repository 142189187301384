// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { createArrayFromStartToN } from "../general_utils";
import indexSelector from "./indexSelector";
import addReverseIndexing from "./utils/addReverseIndexing";
export function indexSelectorToIndexArray(selString, numElements) {
    if (selString === undefined)
        return [];
    let arrayElements = [];
    try {
        const arrayToSelect = createArrayFromStartToN(1, numElements);
        arrayElements = indexSelector(selString, addReverseIndexing(arrayToSelect));
    }
    catch (e) { }
    const _arrayElements = arrayElements.filter((e) => e !== undefined);
    return _arrayElements;
}
