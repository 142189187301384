var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { StructureFromTrajectory, TrajectoryFromModelAndCoordinates, } from "molstar/lib/mol-plugin-state/transforms/model";
import { createModelAndStructure, getModelFromTrajectory } from "./showFile";
import { FileType } from "..";
import { CoordinatesFromMoleculeKit, CoordinatesFromMoleculeKitSet, TrajectoryFromMoleculeKit, TrajectoryFromMoleculeKitSet, } from "./moleculeToCIF";
import { displayMultipleFrames } from "./TrajectoryControls/displayMultipleFrames";
import { displayFrameSelection } from "./MultipleEntrySystemControls/displayMultipleEntries";
export function showStructureWithTrajectoryInViewer(molstar, mol, fileName, ftype) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resSF, reject) => __awaiter(this, void 0, void 0, function* () {
            const _ftype = ftype ? ftype : FileType.trajectory;
            let coords = yield molstar.state.data
                .build()
                .toRoot()
                .apply(CoordinatesFromMoleculeKit, { mol }, {})
                .commit();
            let _trajectory = yield molstar.state.data
                .build()
                .to(coords.ref)
                .apply(TrajectoryFromMoleculeKit, { mol: mol, name: fileName }, {})
                .commit();
            const model = yield molstar.builders.structure.createModel(_trajectory);
            const trajectory = yield molstar
                .build()
                .to(_trajectory.ref)
                .apply(TrajectoryFromModelAndCoordinates, {
                modelRef: model.ref,
                coordinatesRef: coords.ref,
            }, { dependsOn: [model.ref, coords.ref] })
                .commit();
            if (!trajectory || !trajectory.isOk) {
                const fn = fileName ? `${fileName}: ` : "";
                reject(`File ${fn} could not be rendered.`);
                return;
            }
            let cellRefs;
            if (_ftype === FileType.ensemble) {
                cellRefs = yield ensembleFromTraj(molstar, trajectory.ref);
            }
            else if (_ftype === FileType.coordinates_group) {
                cellRefs = yield modelsFromTraj(molstar, trajectory, fileName);
            }
            else {
                cellRefs = yield createTrajCells(molstar, trajectory);
            }
            if (!cellRefs) {
                const fn = fileName ? `${fileName}: ` : "";
                reject(`File ${fn} could not be rendered.`);
            }
            else {
                resSF(cellRefs);
            }
        }));
    });
}
function ensembleFromTraj(molstar, trajectoryRef) {
    return __awaiter(this, void 0, void 0, function* () {
        const ensemble = yield molstar
            .build()
            .to(trajectoryRef)
            .apply(StructureFromTrajectory)
            .commit();
        const cellRefs = Array.isArray(ensemble)
            ? ensemble.map((s) => s.ref)
            : [ensemble.ref];
        return cellRefs;
    });
}
function modelsFromTraj(molstar, trajectory, fileName) {
    return __awaiter(this, void 0, void 0, function* () {
        const structure = yield createModelAndStructure(molstar, trajectory, fileName);
        // TODO: if this needs individual models it should follow the same
        // approach that sdf's will
        const cellRefs = Array.isArray(structure)
            ? structure.map((s) => s.ref)
            : [structure.ref];
        return cellRefs;
    });
}
function createTrajCells(molstar, trajectory) {
    return __awaiter(this, void 0, void 0, function* () {
        yield molstar.builders.structure.hierarchy.applyPreset(trajectory, "default", { showUnitcell: false, representationPreset: "empty" });
        const strucCell = getModelFromTrajectory(molstar, trajectory.ref);
        if (!strucCell) {
            return;
        }
        return [strucCell.sourceRef];
    });
}
export function createTrajObjFromMultipleMols(molstar, mols, fileName, ftype, visibleEntryIDs) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resSF, reject) => __awaiter(this, void 0, void 0, function* () {
            let coords = yield molstar.state.data
                .build()
                .toRoot()
                .apply(CoordinatesFromMoleculeKitSet, { mols }, {})
                .commit();
            let trajectory = yield molstar.state.data
                .build()
                .to(coords.ref)
                .apply(TrajectoryFromMoleculeKitSet, { mols: mols, name: fileName }, {})
                .commit();
            if (!trajectory || !trajectory.isOk) {
                const fn = fileName ? `${fileName}: ` : "";
                reject(`File ${fn} could not be rendered.`);
                return;
            }
            const trajectoryRef = trajectory.ref;
            let ensembleCellRefs;
            if (visibleEntryIDs) {
                ensembleCellRefs = yield displayFrameSelection(trajectoryRef, visibleEntryIDs, molstar);
            }
            else {
                ensembleCellRefs = yield displayMultipleFrames(trajectoryRef, 1, mols.length, 1, molstar);
            }
            resSF(ensembleCellRefs);
        }));
    });
}
