var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import uuidv4 from "../../utils/UUID";
import { dispatchControlledNotification, dispatchDeleteControlledNotification, dispatchNotificationEvent, } from "../../NotificationSystem/utils";
import { addRepresentation } from "../showFile";
import { createMolstarModels, createMolstarModelsMultipleMols, } from "../createMolstarModels";
import { getMoleculeTopologyAndCoordinates } from "../../FileLoaders/loadInMoleculeKit";
export function newSystFromMolObj(molstar, pyodide, system, isSDF, molVarName, systemID = uuidv4()) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const ok = yield pyodide.RunPythonAsync({
                context: {},
                script: `
        add_molecule_to_systems("${systemID}", ${molVarName} )
                `,
            });
            if (!ok)
                throw Error(`Failed to instantiate Molecule class.`);
            const mols = yield getMoleculeTopologyAndCoordinates(isSDF, systemID, pyodide);
            let singleResidue = new Set(mols[0].resname).size === 1;
            let ftype = system.type;
            system.cellRef = [];
            if (isSDF && mols.length > 1) {
                const cellRef = yield createMolstarModelsMultipleMols(molstar, mols, system.name, ftype);
                system.cellRef = cellRef;
                system.visibleElementIDs = system.visibleElementIDs
                    ? system.visibleElementIDs
                    : "all";
                system.numEntries = mols.length;
            }
            else {
                const mol = mols[0];
                const cellRef = yield createMolstarModels(molstar, mol, system.name, ftype);
                system.cellRef = cellRef;
            }
            system.moleculeID = systemID;
            dispatchControlledNotification({
                idx: system.moleculeID,
                type: "info",
                message: `Creating representations...`,
                fileName: system.name,
            });
            system.aromaticBonds = mols[0].bondtype.includes("ar");
            const addedReps = yield addRepresentation(molstar, pyodide, singleResidue, systemID, isSDF, isSDF ? undefined : mols[0], system.cellRef, system.visibility, system.representations, system.aromaticBonds);
            dispatchDeleteControlledNotification({ idx: system.moleculeID });
            if (addedReps) {
                system.representations = addedReps;
            }
        }
        catch (error) {
            console.error(`loadInMoleculeKit failed for ${system.name}.`);
            console.error(error);
            dispatchNotificationEvent({ message: error, type: "error" });
        }
    });
}
