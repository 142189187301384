var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
import { FileType, NAPGenericStore, TableClickAction, } from "..";
import { findSystemFileInTree } from "./utils";
import { focusCoords } from "../3dViewer/Controls/CameraControls/focusCoords";
import { getSystemLoci } from "../3dViewer/Controls/getSystemLoci";
import { StructureSelection } from "molstar/lib/mol-model/structure";
import { Script } from "molstar/lib/mol-script/script";
import { StateObjectRef } from "molstar/lib/mol-state";
import { findTableColName, getAbsPathFromTable, } from "../3dViewer/Controls/utils";
import { SelectionStore } from "../StateStores/selection.store";
import { findOrLoadSystemFromTable } from "./findOrLoadSystemFromTable";
import { setVisibility } from "../3dViewer/VisibilityToggle";
import { updateStateTreeVisibility } from "../3dViewer/stateTree";
import { cameraFocus } from "../3dViewer/Controls/CameraControls/cameraFocus";
// function addCellRefToTableRow(
//   activeTableIdx: number,
//   row: any,
//   cellRefs: string[]
// ) {
//   const tables = NAPTableStore.getState().tables;
//   const rowIdx: number | undefined = row.index;
//   if (activeTableIdx < tables.length && rowIdx !== undefined) {
//     const updated_tables = produce(tables, (draftTables) => {
//       const currentTable = draftTables[activeTableIdx];
//       currentTable.rows[rowIdx]["cellRefs"] = cellRefs;
//     });
//     NAPTableStore.getState().set_tables(updated_tables);
//   }
// }
function getTableColNameFromConfig(configOpt, row, n) {
    if (!configOpt)
        return undefined;
    const colNamePre = configOpt[n];
    let colVal;
    const colName = colNamePre ? findTableColName(row, colNamePre) : undefined;
    if (colName) {
        colVal = row.original[colName];
    }
    return colVal;
}
function showSystemEntry(vss, molstar, pyodide, row, actionDat, activeTableIdx, DCpath, localBasePath, rowVisibility) {
    var _a, e_1, _b, _c;
    var _d, _e, _f;
    return __awaiter(this, void 0, void 0, function* () {
        const cellRefs = [];
        let openSystPanel = false;
        let n = 0;
        const representations = (_d = actionDat.options) === null || _d === void 0 ? void 0 : _d.representations;
        try {
            for (var _g = true, _h = __asyncValues(actionDat.column), _j; _j = yield _h.next(), _a = _j.done, !_a;) {
                _c = _j.value;
                _g = false;
                try {
                    const actionColumn = _c;
                    const loadRowCol = findTableColName(row, actionColumn);
                    if (!loadRowCol)
                        continue;
                    const fileName = row.original[loadRowCol];
                    if (!fileName)
                        continue;
                    const systemName = getTableColNameFromConfig((_e = actionDat.options) === null || _e === void 0 ? void 0 : _e.fileName_column, row, n);
                    const colIdx = getTableColNameFromConfig((_f = actionDat.options) === null || _f === void 0 ? void 0 : _f.showIndexColumn, row, n);
                    n += 1;
                    const { system, loadedNewFile } = yield findOrLoadSystemFromTable(vss, molstar, pyodide, fileName, representations, systemName, DCpath, localBasePath);
                    const cellRef = system === null || system === void 0 ? void 0 : system.cellRef;
                    if (cellRef === undefined)
                        continue;
                    openSystPanel = loadedNewFile ? loadedNewFile : openSystPanel;
                    if ((system === null || system === void 0 ? void 0 : system.type) !== FileType.sdf &&
                        (system === null || system === void 0 ? void 0 : system.type) !== FileType.coordinates_group)
                        continue;
                    const systemPosIdx = system === null || system === void 0 ? void 0 : system.stateTreePosition;
                    if (systemPosIdx === undefined)
                        continue;
                    const colIdxNum = Number(colIdx);
                    if (Number.isNaN(colIdxNum))
                        continue;
                    let selectedEntries;
                    // Update shown system elements
                    // if the system was hidden, show only the element in the table row
                    // otherwise add or substract the element
                    if (system.visibility === false && rowVisibility) {
                        vss.getState().updateSystemParameters(systemPosIdx, {
                            visibleElementIDs: `${colIdxNum}`,
                        });
                        selectedEntries = [colIdxNum];
                    }
                    else {
                        selectedEntries = vss
                            .getState()
                            .toggleSystemEntry(systemPosIdx, colIdxNum, true);
                    }
                    // Update system visibility
                    // If no shown elements are returned, hide the system
                    // Otherwise, show it if it was hidden, and focus.
                    if (selectedEntries && selectedEntries.length === 0) {
                        setVisibility(molstar, cellRef, false);
                        updateStateTreeVisibility(vss, systemPosIdx, false, molstar);
                        const activeSystemPosition = NAPGenericStore.getState()._activeSystemPosition;
                        if (activeSystemPosition &&
                            JSON.stringify(activeSystemPosition) === JSON.stringify(systemPosIdx)) {
                            NAPGenericStore.getState().closeSystemRepresentations();
                        }
                    }
                    else if (rowVisibility) {
                        if (system.visibility === false) {
                            setVisibility(molstar, cellRef, true);
                            updateStateTreeVisibility(vss, systemPosIdx, true, molstar);
                        }
                        cameraFocus(molstar, cellRef);
                    }
                }
                finally {
                    _g = true;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (!_g && !_a && (_b = _h.return)) yield _b.call(_h);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return { cellRefs: cellRefs, openSystPanel: openSystPanel };
    });
}
function tableLoadFileAction(vss, molstar, pyodide, row, actionDat, activeTableIdx, DCpath, localBasePath, visibility) {
    var _a, e_2, _b, _c;
    var _d, _e, _f;
    return __awaiter(this, void 0, void 0, function* () {
        const cellRefs = [];
        let openSystPanel = false;
        let n = 0;
        const representations = (_d = actionDat.options) === null || _d === void 0 ? void 0 : _d.representations;
        try {
            for (var _g = true, _h = __asyncValues(actionDat.column), _j; _j = yield _h.next(), _a = _j.done, !_a;) {
                _c = _j.value;
                _g = false;
                try {
                    const actionColumn = _c;
                    n += 1;
                    const loadRowCol = findTableColName(row, actionColumn);
                    if (!loadRowCol)
                        continue;
                    const fileName = row.original[loadRowCol];
                    if (!fileName)
                        continue;
                    const systemNameColPre = ((_e = actionDat.options) === null || _e === void 0 ? void 0 : _e.fileName_column)
                        ? (_f = actionDat.options) === null || _f === void 0 ? void 0 : _f.fileName_column[n]
                        : undefined;
                    let systemName;
                    const systemNameCol = systemNameColPre
                        ? findTableColName(row, systemNameColPre)
                        : undefined;
                    if (systemNameCol) {
                        systemName = row.original[systemNameCol];
                    }
                    const { system, loadedNewFile } = yield findOrLoadSystemFromTable(vss, molstar, pyodide, fileName, representations, systemName, DCpath, localBasePath);
                    const cellRef = system === null || system === void 0 ? void 0 : system.cellRef;
                    openSystPanel = loadedNewFile ? loadedNewFile : openSystPanel;
                    if (cellRef) {
                        cellRefs.push(cellRef);
                    }
                }
                finally {
                    _g = true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (!_g && !_a && (_b = _h.return)) yield _b.call(_h);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return { cellRefs: cellRefs, openSystPanel: openSystPanel };
    });
}
function tableFocusCoords(row, actionDat, molstar) {
    for (const actionColumn of actionDat.column) {
        const actionCol = findTableColName(row, actionColumn);
        if (!actionCol)
            continue;
        const coords = row.original[actionCol];
        if (!coords)
            continue;
        try {
            const coordsArray = JSON.parse(coords);
            focusCoords(molstar, coordsArray);
        }
        catch (e) {
            console.error(e);
        }
    }
}
function tableHighlightSystem(row, actionDat, vss, molstar, localBasePath) {
    var _a, _b;
    const s = vss.getState().loaded_structures;
    let highlightIdx = 0;
    for (const actionColumn of actionDat.column) {
        highlightIdx++;
        const highlightSystCol = findTableColName(row, actionColumn);
        if (!highlightSystCol)
            continue;
        const fileName = row.original[highlightSystCol];
        if (!fileName)
            continue;
        let absPath;
        if (localBasePath) {
            absPath = getAbsPathFromTable(fileName, localBasePath);
        }
        const syst = findSystemFileInTree(s, absPath);
        if (!syst)
            continue;
        const selectionColumns = (_a = actionDat.options) === null || _a === void 0 ? void 0 : _a.selectionColumn;
        const cellRef = syst.cellRef && syst.cellRef.length > 0 ? syst.cellRef[0] : undefined;
        if (!cellRef)
            continue;
        let loci;
        if (selectionColumns && selectionColumns.length > 0) {
            const selectionColumn = selectionColumns[highlightIdx - 1];
            if (!selectionColumn)
                continue;
            const selectionColumnOk = findTableColName(row, selectionColumn);
            if (!selectionColumnOk)
                continue;
            const selectedStruct = StateObjectRef.resolveAndCheck(molstar.state.data, cellRef);
            const structureData = (_b = selectedStruct === null || selectedStruct === void 0 ? void 0 : selectedStruct.obj) === null || _b === void 0 ? void 0 : _b.data;
            if (!structureData)
                continue;
            const selStr = row.original[selectionColumnOk];
            let selIndexes;
            try {
                selIndexes = JSON.parse(selStr);
            }
            catch (_c) {
                continue;
            }
            const sel = Script.getStructureSelection((Q) => Q.struct.generator.atomGroups({
                "atom-test": Q.core.set.has([
                    Q.core.type.set(selIndexes),
                    Q.struct.atomProperty.core.sourceIndex(),
                ]),
            }), structureData);
            loci = StructureSelection.toLociWithSourceUnits(sel);
        }
        else {
            const lociArr = getSystemLoci(cellRef, molstar);
            if (!lociArr)
                return;
            loci = lociArr[0];
        }
        if (loci) {
            if (!SelectionStore.getState().viewerSelectionMode) {
                molstar.managers.structure.focus.clear();
                SelectionStore.getState().toggleViewerSelectionMode(molstar);
            }
            SelectionStore.getState().setSelectionUnit("element", molstar);
            molstar.managers.structure.selection.fromLoci("add", loci);
        }
    }
}
function tableToggleSystemViz(vss, row, actionDat) {
    const cellRefs = [];
    let setVisible = false;
    for (const actionColumn of actionDat.column) {
        const loadRowCol = findTableColName(row, actionColumn);
        if (!loadRowCol)
            continue;
        const fileName = row.original[loadRowCol];
        if (!fileName)
            continue;
        const s = vss.getState().loaded_structures;
        const syst = findSystemFileInTree(s, fileName);
        if (!syst)
            continue;
        const systCellRef = syst.cellRef;
        if (systCellRef && systCellRef.length > 0) {
            cellRefs.push(systCellRef);
        }
    }
    return cellRefs;
}
export function tableOnClickAction(vss, molstar, pyodide, rowActions, row, activeTableIdx, openSystemsPanel, DCpath, localBasePath, rowIsActive) {
    var _a, rowActions_1, rowActions_1_1;
    var _b, e_3, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        let cellRefs = [];
        let visibility = true;
        let openSystPanelBool;
        try {
            for (_a = true, rowActions_1 = __asyncValues(rowActions); rowActions_1_1 = yield rowActions_1.next(), _b = rowActions_1_1.done, !_b;) {
                _d = rowActions_1_1.value;
                _a = false;
                try {
                    const actionDat = _d;
                    let _cellRefs;
                    visibility = !rowIsActive;
                    switch (actionDat.action) {
                        case TableClickAction.loadFile:
                            let _openSystPanelBool;
                            ({ cellRefs: _cellRefs, openSystPanel: _openSystPanelBool } =
                                yield tableLoadFileAction(vss, molstar, pyodide, row, actionDat, activeTableIdx, DCpath, localBasePath, visibility));
                            openSystPanelBool = _openSystPanelBool
                                ? _openSystPanelBool
                                : openSystPanelBool;
                            break;
                        case TableClickAction.showSystemEntry:
                            let _openSystPanelBool2;
                            ({ cellRefs: _cellRefs, openSystPanel: _openSystPanelBool2 } =
                                yield showSystemEntry(vss, molstar, pyodide, row, actionDat, activeTableIdx, DCpath, localBasePath, visibility));
                            openSystPanelBool = _openSystPanelBool2
                                ? _openSystPanelBool2
                                : openSystPanelBool;
                            break;
                        case TableClickAction.toggleVisibility:
                            _cellRefs = tableToggleSystemViz(vss, row, actionDat);
                            break;
                        case TableClickAction.focusCoords:
                            tableFocusCoords(row, actionDat, molstar);
                            break;
                        case TableClickAction.highlightSystem:
                            tableHighlightSystem(row, actionDat, vss, molstar, localBasePath);
                            break;
                        default:
                            break;
                    }
                    if (_cellRefs && _cellRefs.length > 0) {
                        cellRefs.push(..._cellRefs);
                    }
                }
                finally {
                    _a = true;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (!_a && !_b && (_c = rowActions_1.return)) yield _c.call(rowActions_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        if (openSystPanelBool && openSystemsPanel) {
            openSystemsPanel();
        }
        return { cellRefs: cellRefs, visibility: visibility };
    });
}
