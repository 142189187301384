import { jsx as _jsx } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Link } from "@mui/material";
import { memo } from "react";
import { NAPMSAStore } from "../StateStores";
import { shallow } from "zustand/shallow";
import { posIsHighlighted } from "../Sequence/utils";
export function MSASequence({ seqElements, onMouseMove, onMouseDown, onMouseUp, mappedPositions, selectedPoses, msaColoring, }) {
    let mappingIdx = 0;
    const msaPositions = seqElements.map((seq, i) => {
        const isMapped = mappedPositions === null || mappedPositions === void 0 ? void 0 : mappedPositions.includes(i);
        let color;
        if (isMapped) {
            color = msaColoring ? msaColoring[mappingIdx] : undefined;
            mappingIdx += 1;
        }
        const isSelected = selectedPoses === null || selectedPoses === void 0 ? void 0 : selectedPoses.includes(i);
        return (_jsx(MSAPosition, { label: seq, posIdx: i, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onMouseUp: onMouseUp, isMapped: isMapped, isSelected: isSelected, color: color }, `msa-position-${i}`));
    });
    return (_jsx(Box, Object.assign({ display: "flex", flexDirection: "row", sx: { height: "100%" } }, { children: msaPositions })));
}
const MSAPosition = memo(MSAPositionMemo);
function MSAPositionMemo({ label, posIdx, isMapped, isSelected, color, onMouseMove, onMouseDown, onMouseUp, }) {
    const resultSelector = (state) => {
        const isHovered = state.hoveredMsaPos === posIdx;
        const isHighlighted = isHovered
            ? true
            : posIsHighlighted(state.mouseDownPosition, state.lastHoveredMsaPosInSelection, posIdx);
        return isHighlighted;
    };
    const isHighlighted = NAPMSAStore(resultSelector, shallow);
    let backgroundColor;
    let fontColor = "#262626";
    let fontWeight;
    if (isMapped) {
        if (isHighlighted) {
            backgroundColor = "secondary.main";
        }
        else if (isSelected) {
            fontColor = "black";
            fontWeight = "bolder";
            if (color) {
                backgroundColor = color;
            }
            else {
                backgroundColor = "#338bff";
            }
        }
        else if (color) {
            backgroundColor = color;
        }
        else {
            backgroundColor = "#d0e7fb";
        }
    }
    else {
        fontColor = "#999999";
        if (isHighlighted) {
            backgroundColor = "#ffdeb3";
        }
    }
    // if (isMapped) {
    //   if (isHighlighted) {
    //     backgroundColor = "secondary.main";
    //     fontColor = "black";
    //   } else if (isSelected) {
    //     fontColor = "black";
    //     if (color) {
    //       backgroundColor = color;
    //     } else {
    //       backgroundColor = "#338bff";
    //     }
    //   } else {
    //     if (color) {
    //       fontColor = color;
    //     } else {
    //       fontColor = "black";
    //     }
    //   }
    // } else {
    //   fontColor = "#999999";
    //   if (isHighlighted) {
    //     backgroundColor = "#ffdeb3";
    //   }
    // }
    return (_jsx(Link, Object.assign({ id: `msa-position-${posIdx}`, className: "msaPosition", component: "button", underline: "none", sx: {
            fontWeight: fontWeight,
            fontFamily: "monospace",
            fontSize: "0.8rem",
            color: fontColor,
            backgroundColor: backgroundColor,
        }, onMouseEnter: (e) => {
            onMouseMove(e, posIdx);
        }, onMouseDown: (e) => {
            onMouseDown(e, posIdx);
        }, onMouseUp: (e) => {
            onMouseUp(e, posIdx);
        }, onTouchStart: (e) => {
            onMouseDown(e, posIdx);
        }, onTouchEnd: (e) => {
            onMouseUp(e, posIdx);
        } }, { children: label })));
}
