import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { Box, Link, Typography } from "@mui/material";
import { StructureElement } from "molstar/lib/mol-model/structure";
import { shallow } from "zustand/shallow";
import { memo, useEffect, useRef, useState } from "react";
import { Representation } from "molstar/lib/mol-repr/representation";
import { ButtonsType, getButton, getButtons, getModifiers, } from "molstar/lib/mol-util/input/input-observer";
import { selectResidueRange } from "./selectResidueRange";
import { lociFromSelection, posIsHighlighted, } from "./utils";
import { SelectionStore } from "../StateStores/selection.store";
import { arePositionPropsEqual, areSegmentPropsEqual, areSequencePropsEqual, } from "./compareSeqComponentsProp";
import { throttle } from "../utils/throttle";
export const Sequence = memo(SequenceMemo, areSequencePropsEqual);
function SequenceMemo({ cellRef, name, isVisible, molstar, selectedSystResidues, seqIsClicked, structureData, seqData, }) {
    const [viewerSelectionMode, selectionUnit, setClickedSequence] = SelectionStore((state) => [
        state.viewerSelectionMode,
        state.selectionUnit,
        state.setClickedSequence,
    ], shallow);
    const componentRef = useRef();
    useEffect(() => {
        if (!seqIsClicked) {
            // on mouseUp outide of the component, stop selection
            setMouseDownPosition(undefined);
            setLastMouseOverPosition({ posId: -1 });
            molstar.managers.interactivity.lociHighlights.clearHighlights();
        }
    }, [seqIsClicked]);
    const [mouseDownPosition, setMouseDownPosition] = useState(undefined);
    const [lastMouseOverPosition, setLastMouseOverPosition] = useState({ posId: -1 });
    // const { structureData, seqData } = useMemo(() => {
    //   console.log("!!!!!");
    //   return getStructureObjects(cellRef, molstar);
    // }, [cellRef]);
    const handleClick = (loci, e) => {
        const buttons = getButtons(e.nativeEvent);
        const button = getButton(e.nativeEvent);
        const modifiers = getModifiers(e.nativeEvent);
        const ev = {
            current: Representation.Loci.Empty,
            buttons,
            button,
            modifiers,
        };
        if (loci && !StructureElement.Loci.isEmpty(loci)) {
            ev.current = { loci };
        }
        molstar.behaviors.interaction.click.next(ev);
    };
    const selectChain = (chain, e) => {
        const loci = lociFromSelection(structureData, undefined, chain);
        handleClick(loci, e);
        setMouseDownPosition(undefined);
    };
    const hoverChain = (chain, e) => {
        if (!viewerSelectionMode)
            return;
        const buttons = getButtons(e.nativeEvent);
        const button = getButton(e.nativeEvent);
        const modifiers = getModifiers(e.nativeEvent);
        const loci = lociFromSelection(structureData, undefined, chain);
        throttleHighlight(loci, buttons, button, modifiers);
        // handleClick(loci, e);
        // setMouseDownPosition(undefined);
    };
    const onMouseDown = (posId, resId, chain, e) => {
        const loci = lociFromSelection(structureData, resId, chain);
        handleClick(loci, e);
        setMouseDownPosition({ resId, chain, posId });
        setClickedSequence({ cellRef: cellRef, HTMLElement: componentRef.current });
    };
    const onMouseUp = (positionId, resId, chain, e) => {
        if (mouseDownPosition === undefined)
            return;
        const lociRange = selectResidueRange(seqData, mouseDownPosition.resId, mouseDownPosition.chain, resId, chain, structureData);
        if (lociRange)
            handleClick(lociRange, e);
        setMouseDownPosition(undefined);
    };
    const handleHighlight = (loci, buttons, button, modifiers) => {
        const ev = {
            current: Representation.Loci.Empty,
            buttons,
            button,
            modifiers,
        };
        if (loci && !StructureElement.Loci.isEmpty(loci)) {
            ev.current = { loci };
        }
        molstar.behaviors.interaction.hover.next(ev);
    };
    const throttleHighlight = throttle(handleHighlight, 250);
    const onMouseMove = (posId, resId, chain, e) => {
        // set lastMouseOverSeqIdx to -1 and stop highlightQueue when mouse out of this comonent
        if (lastMouseOverPosition.posId === posId) {
            return;
        }
        else {
            const buttons = getButtons(e.nativeEvent);
            const button = getButton(e.nativeEvent);
            const modifiers = getModifiers(e.nativeEvent);
            setLastMouseOverPosition({ posId, resId, chain });
            const loci = lociFromSelection(structureData, resId, chain);
            if (!loci)
                return;
            if (mouseDownPosition !== undefined) {
                // molstar.managers.interactivity.lociHighlights.highlightOnly({ loci });
                if (viewerSelectionMode) {
                    const cb = (loci) => {
                        molstar.managers.interactivity.lociHighlights.highlightOnly({
                            loci,
                        });
                    };
                    const lociRange = selectResidueRange(seqData, mouseDownPosition.resId, mouseDownPosition.chain, resId, chain, structureData);
                    if (lociRange)
                        cb(lociRange);
                }
                else {
                    handleHighlight(loci, ButtonsType.Flag.None, ButtonsType.Flag.None, Object.assign(Object.assign({}, modifiers), { shift: true }));
                }
            }
            else {
                throttleHighlight(loci, buttons, button, modifiers);
            }
        }
    };
    // const onPositionHover = (resId: number, chain: string) => {
    //   const loci = lociFromSelection(resId, chain);
    //   if (!loci) return;
    //   molstar.managers.interactivity.lociHighlights.highlightOnly({ loci });
    // };
    const systNameSpace = "100px";
    let positionId = 0;
    return cellRef && isVisible ? (_jsx(Box, Object.assign({ display: "flex", flexDirection: "row", gap: 2, sx: {
            // pl: `calc(${systNameSpace} + 0.5rem)`,
            "&:hover": {
                "& .sequencePosition": {
                    backgroundColor: selectionUnit === "model" ? "secondary.main" : undefined,
                },
            },
        }, ref: componentRef }, { children: seqData.map((seq) => {
            const segmentComp = (_jsx(Segment, Object.assign({ positionId: positionId }, seq, { onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseMove: onMouseMove, selectedChainResidues: selectedSystResidues ? selectedSystResidues[seq.chain] : undefined, mouseDownPosId: viewerSelectionMode && mouseDownPosition
                    ? mouseDownPosition.posId
                    : undefined, lastMouseOverPosition: viewerSelectionMode && lastMouseOverPosition.posId !== -1
                    ? lastMouseOverPosition
                    : undefined, selectChain: selectChain, hoverChain: hoverChain, selectionUnit: selectionUnit, cellRef: cellRef }), seq.chain));
            positionId += seq.seqIds.length;
            return segmentComp;
        }) }))) : (_jsx(_Fragment, {}));
}
const Segment = memo(SegmentMemo, areSegmentPropsEqual);
function SegmentMemo({ chain, seqIds, labels, positionId, selectionUnit, selectedChainResidues, onMouseDown, onMouseUp, onMouseMove, mouseDownPosId, lastMouseOverPosition, selectChain, hoverChain, cellRef, }) {
    const hoveredChain = chain === (lastMouseOverPosition === null || lastMouseOverPosition === void 0 ? void 0 : lastMouseOverPosition.chain) &&
        ["chain", "entity"].includes(selectionUnit);
    return (_jsxs(Box, Object.assign({ sx: {
            position: "relative",
            textAlign: "left",
            ml: "18px",
        } }, { children: [_jsx(Link, Object.assign({ component: "button", underline: "none", variant: "caption", color: "white", sx: {
                    px: "4px",
                    backgroundColor: "primary.main",
                    position: "absolute",
                    top: "3px",
                    left: "-18px",
                    fontSize: "10px",
                    fontWeight: "bold",
                }, onClick: (e) => selectChain(chain, e), onMouseEnter: (e) => hoverChain(chain, e) }, { children: chain })), _jsx(Box, Object.assign({ display: "flex", flexDirection: "row", sx: {
                    "&:hover": {
                        "& .sequencePosition": {
                            backgroundColor: hoveredChain ? "secondary.main" : undefined,
                        },
                    },
                } }, { children: seqIds.map((seqId, i) => {
                    return (_jsx(Position, { cellRef: cellRef, resId: seqId, label: labels[i], positionId: positionId + i, showPosition: i % 10 === 0, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseMove: onMouseMove, chain: chain, selected: selectedChainResidues && selectedChainResidues.includes(seqId), mouseDownPosId: mouseDownPosId, lastMouseOverPosId: lastMouseOverPosition ? lastMouseOverPosition.posId : undefined }, `${chain}-${seqId}`));
                }) }))] })));
}
const Position = memo(PositionMemo, arePositionPropsEqual);
function PositionMemo({ positionId, resId, label, showPosition, onMouseDown, onMouseUp, onMouseMove, chain, selected, mouseDownPosId, lastMouseOverPosId, }) {
    const highlighted = posIsHighlighted(mouseDownPosId, lastMouseOverPosId, positionId);
    return (_jsxs(Box, Object.assign({ sx: {
            position: "relative",
            textAlign: "left",
            mt: "15px",
        } }, { children: [showPosition && (_jsx(Typography, Object.assign({ variant: "caption", color: "primary", sx: {
                    position: "absolute",
                    top: "-12px",
                    fontSize: "10px",
                } }, { children: resId }))), _jsxs(Link, Object.assign({ className: "sequencePosition", component: "button", underline: "none", sx: {
                    fontFamily: "monospace",
                    fontSize: "0.8rem",
                    color: "black",
                    backgroundColor: highlighted
                        ? "secondary.main"
                        : selected
                            ? "#338bff"
                            : undefined,
                    "&:hover": {
                        backgroundColor: "secondary.main",
                    },
                }, 
                // onClick={(e) => {
                //   onMouseDown(resId, chain, e);
                // }}
                onMouseDown: (e) => {
                    onMouseDown(positionId, resId, chain, e);
                }, onMouseUp: (e) => {
                    onMouseUp(positionId, resId, chain, e);
                }, onTouchStart: (e) => {
                    onMouseDown(positionId, resId, chain, e);
                }, onTouchEnd: (e) => {
                    onMouseUp(positionId, resId, chain, e);
                }, 
                // onMouseMove={(e) => {
                //   mouseMove(positionId, resId, chain, e);
                // }}
                onMouseEnter: (e) => {
                    onMouseMove(positionId, resId, chain, e);
                } }, { children: [" ", label] }))] })));
}
