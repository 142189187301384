import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// (c) 2023 Acellera Ltd http://www.acellera.com
// All Rights Reserved
// No redistribution in whole or part
//
import { useEffect, useState } from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { Box } from "@mui/material";
export function VerticalDragHandler({ onSetWidthCb, onSetIsResizingCb, }) {
    const [isResizing, setIsResizing] = useState(false);
    const [width, setWidth] = useState(window.innerWidth * 0.4);
    const [isDefaultWidth, setIsDefaultWidth] = useState(true);
    const [handlerMovePos, setHandlerMovePos] = useState({
        widthTmp: window.innerWidth * 0.4,
        shadowWidth: 0,
        shadowLeft: 0,
    });
    // Handle mouse down when resizing starts
    const handleMouseDown = (e) => {
        setHandlerMovePos({
            widthTmp: width,
            shadowWidth: 0,
            shadowLeft: 0,
        });
        setIsResizing(true); // Enable resizing
        onSetIsResizingCb(true);
    };
    const minWidth = 56;
    const handlerSize = 12;
    const maxWidth = window.innerWidth - handlerSize;
    const getNewWidth = (mousePos) => {
        let _mousePos = mousePos < 56 ? 56 : mousePos; // avoid getting under left side bar
        if (mousePos < minWidth) {
            _mousePos = minWidth;
        }
        else if (mousePos > maxWidth) {
            _mousePos = maxWidth;
        }
        else {
            _mousePos = mousePos;
        }
        return window.innerWidth - _mousePos;
    };
    // Handle mouse move during the resizing process
    const handleMouseMove = (e) => {
        if (isResizing) {
            const newWidthTmp = getNewWidth(e.clientX); // Calculate new width based on distance from right edge
            let shadowLeft;
            let shadowWidth;
            if (newWidthTmp >= width) {
                shadowWidth = newWidthTmp - width;
                shadowLeft = window.innerWidth - newWidthTmp + handlerSize;
            }
            else {
                shadowWidth = width - newWidthTmp;
                shadowLeft = window.innerWidth - width;
            }
            setHandlerMovePos({
                widthTmp: newWidthTmp,
                shadowWidth: shadowWidth,
                shadowLeft: shadowLeft,
            });
        }
    };
    // Handle mouse up to stop resizing
    const handleMouseUp = (e) => {
        if (isResizing) {
            const newWidth = getNewWidth(e.clientX); // Calculate new width based on distance from right edge
            setWidth(newWidth); // Update width dynamically
            setIsDefaultWidth(false);
            onSetWidthCb(newWidth);
        }
        setIsResizing(false); // Disable resizing
        onSetIsResizingCb(false);
    };
    // Add event listeners for mouse move and mouse up
    useEffect(() => {
        if (isResizing) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        }
        else {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        }
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isResizing]);
    useEffect(() => {
        const handleResize = () => {
            setHandlerMovePos({
                widthTmp: isDefaultWidth ? window.innerWidth * 0.4 : width,
                shadowWidth: 0,
                shadowLeft: 0,
            });
            const resizeLimit = window.innerWidth - minWidth - 12;
            if (width > resizeLimit) {
                setWidth(resizeLimit);
                setIsDefaultWidth(false);
                onSetWidthCb(resizeLimit);
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [width, isDefaultWidth]);
    const leftEdge = window.innerWidth - handlerMovePos.widthTmp;
    return (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ onMouseDown: handleMouseDown, sx: {
                    width: `${handlerSize}px`,
                    height: "100%",
                    backgroundColor: "#f5f5f5",
                    position: "fixed",
                    left: `${leftEdge}px`,
                    // left: 0, // Position the handle on the left-most edge of the component
                    top: 0,
                    cursor: "col-resize",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                } }, { children: _jsx(DragHandleIcon, { fontSize: "small", sx: {
                        transform: "rotate(90deg)",
                        color: "rgba(0,0,0,0.5)",
                    } }) })), isResizing && (_jsx("div", { style: {
                    position: "fixed",
                    top: 0,
                    left: `${handlerMovePos.shadowLeft}px`,
                    height: "100%",
                    width: `${handlerMovePos.shadowWidth}px`,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    pointerEvents: "none",
                    zIndex: 2, // Ensure it's behind the handler
                } }))] }));
}
